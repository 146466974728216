<template>
  <div id="app" :class="dark == false ? '' : 'dark'">
    <main>
      <div class="alignslider" >
        Darkmode
        <label class="switch">
  <input type="checkbox" @click="toggleMode">
  <span class="slider round"></span>
</label>
        </div>
      <div class="search-box">
        <input
          type="text"
          class="search-bar"
          placeholder="Suche nach Stadt"
          v-model="query"
          @keypress="fetchWeather"          
        />
      </div>

      <div class="weather-wrap" v-if="typeof weather.main != 'undefined'">
        <div class="location-box">
          <div class="location">
            {{ weather.name }}, {{ weather.sys.country }}
          </div>
          <div class="date">
            {{ dateBuilder() }}
          </div>
        </div>
        <div class="weather-box">
          <div id="temp" class="temp" :class="dark == false ? '' : 'dark'" >{{ Math.round(weather.main.temp) }}°C</div>
          <div class="weather">
            <div class="description"> {{ weather.weather[0].description }}</div>
            <img :src="image" alt="">
        <!-- weather.weather[0].main == 'Clear' ? 'Klar' : weather.weather[0].main}}-->
       
          </div>
          <div class="feelslike">
            Fühlt sich an wie: {{ Math.round(weather.main.feels_like) }}°C
          </div>
        </div>
        <div class="grid-container">          
            <div class="grid-item">Feuchte: {{ weather.main.humidity }} %</div>
            <div class="grid-item">Luftdruck: {{ weather.main.pressure }} hPa</div>         
            <div class="grid-item">Wind: {{ weather.wind.speed }} m/s</div>              
            <div class="grid-item">Wolken {{ weather.clouds.all }} %</div>
            <div class="grid-item">+{{ weather.timezone / 60 / 60 }} UTC</div>
            <div class="grid-item"></div>  
            <div class="grid-item"> Sonnaufgang:<br>{{ timestampConverter(weather.sys.sunrise) }}</div> 
            <div class="grid-item"> Sonnenuntergang:<br>{{ timestampConverter(weather.sys.sunset) }}</div>
            
        </div>
      
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "app",
  
    data() {
    
    return {
      api_key: "0d629baa57aa418ec778fef5cd04ad50",
      url_base: "https://api.openweathermap.org/data/2.5/",
      query: "",
      weather: {},
      dark: false,
      image: '',
      
    };
  },
  methods: {

    toggleMode() {
this.dark = !this.dark;
    },
    fetchWeather(e) {
      if (e.key == "Enter") {
        fetch(
          `${this.url_base}weather?q=${this.query}&units=metric&APPID=${this.api_key}&lang=de`
        )
          .then((res) => {
            return res.json();
          })
          .then(this.setResults);
      }
    },
    setResults(results) {
      this.weather = results;
      let urlprefix = "http://openweathermap.org/img/wn/";
      let urlsuffix= "@2x.png";
      this.image = urlprefix + this.weather.weather[0].icon + urlsuffix;
      

    },
    dateBuilder() {
      let d = new Date();
      let months = [
        "January",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
      let days = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ];

      let day = days[d.getDay()];
      let date = d.getDate();
      let month = months[d.getMonth()];
      let year = d.getFullYear();

      return `${day}, ${date}. ${month} ${year}`;
    },
    timestampConverter(q) {
      let unixTimestamp = q;
      let dateObj = new Date(unixTimestamp * 1000);
      let utcString = dateObj.toUTCString();
      let time = utcString.slice(-11, -4);
      return time;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Ubuntu", sans-serif;
  color:#404040;  
}
#app {
  background-color: white;
  transition: 0.4s;
}

#app.dark {
  background-color: #1E1E1E;
  color:white;
  transition: 0.4s;
}
main {
  min-height: 100vh;
  padding: 25px;  
}
.search-box {
  width: 100%;
  margin-bottom: 30px;
}
.search-box .search-bar {
  display: block;
  width: 300px;
  padding: 15px;
  margin: 0 auto;  
  font-size: 18px;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px 16px 16px 16px;
  transition: 0.4s;
  color:#404040;
}
.search-box .search-bar:focus {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 16px 0px 16px 16px;
  
}

.location-box .location {
  
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  margin-top: -20px;  
}

.location-box .date {  
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.weather-box {
  text-align: center;
}

.weather-box .temp {
  display: inline-block;
  padding: 5px 25px;  
  font-size: 80px;
  font-weight: 800;
  text-shadow: 2px 4px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  margin: 0px 0px 20px 0px;
  box-shadow: 3px 6px rgba(0, 0, 0, 0.25);
  color:#202731;
}

#temp.dark {

  background-color: #1D1D1D;
  transition: 0.4s;
  color:white;
}

.weather-box  {  
  font-size: 32px;
  font-weight: 400;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
}

.weather-box,
.feelslike {  
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;  
}

.feelslike {  
 font-style: italic;  
}
 
.grid-container {
  display: grid;
  grid-template-columns: auto auto ;  
  width:320px;
  margin:auto;
  margin-top:10px;
    place-items: center;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
  
}

.grid-item {
 padding:5px;  
  
  text-align: center;
  overflow: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.alignslider { 
  width: 300px; 
  margin: 0 auto;  
  text-align: right;    
  font-size: 10px;
  line-height: 20px;
  height: 30px;
 
}
</style>
